<template>
  <!-- buyer dashboard my Reservations page -->
  <div>
    <!--
    <TheTitleBar
      sectionTitle="dashboard.label.mnuReservations"
      sectionID="buyerReservations"
      :showCount="true"
      :showValue="false"
      :showFilters="true"
      :showSort="true"
      :showSellButton="false"
      :totalCount="totalVehicles"
      @qSortValue="sort"
      @qDateFilter="filterList"
    />
-->
    <div class="mx-4 mx-lg-0">
      <div class="vehicle-count" v-if="!loading && !error">
        {{ reservationList.CardsData.length }} {{$t("dashboard.label.vehicles")}}
      </div>
      <b-row align-h="center" v-if="(!reservationList.CardsData || reservationList.CardsData.length < 1) && !loading" class="p-5">
        <h1 class="p-5 my-5 text-secondary text-center">{{$t("dashboard.label.NoVehicleWasFound")}}</h1>
      </b-row>
      <div class="card-wrapper" v-else-if="loading">
        <div
            class="item"
            v-for="(item, index) in new Array(12)"
            :key="'loader' + index + item"
        >
          <v-skeleton-loader
              class="m3x-auto my-2 px-2"
              max-width="100%"
              type="image, article"
          />
        </div>
      </div>

      <div class="card-wrapper" v-else-if="reservationList">
        <div class="item" v-for="carDetail in cardsData" :key="carDetail.ListingId">
          <div @click.prevent.stop style="pointer-events: none">
            <TheCarCard
                :carDetail="carDetail"
                :price="carDetail.Price"
                :status="true"
                :reservation="true"
            />
          </div>
          <!-- buttonType="confirm" -->
          <div class="watchlist-card-footer">
            <div class="title">{{carDetail.Status}}</div>
            <div class="date">{{reservedDate(carDetail)}}</div>
            <div class="text-right">
              <a :href="$store.getters.hostURL + carDetail.Attachment" target="_blank" class="details-btn">{{$t("dashboard.label.SeeDetails")}}</a>
            </div>
          </div>
        </div>
      </div>
      <b-container v-else>
        <h1 class="p-5 my-5 text-secondary text-center">{{$t("dashboard.label.NoVehicleWasFound")}}</h1>
      </b-container>
    </div>
  </div>
</template>

<script>
// import TheTitleBar from "@/components/dashboard/global/TheTitleBar.vue";
import TheCarCard from "@/components/global/TheCarCard.vue";
import moment from "moment";
import {mapGetters} from "vuex";

export default {
  components: {
    // TheTitleBar,
    TheCarCard,
  },
  data() {
    return {
      sortBy: "Defaults",
      filterByDate: "Defaults",
      filterByStatus: "Defaults",
      qDateFilter: null,
      statusFilter: null,
      sortOrder: 0,
      loading: true,
      error: false,
      totalVehicles: this.cardsData ? this.cardsData.length : 0,
    };
  },
  computed: {
    ...mapGetters(["reservationList"]),
    cardsData() {
      const l = this.reservationList.CardsData;
      return l ? l : [];
    }

  },
  methods: {
    reservedDate(carDetail) {
      return moment(carDetail.DateOfLastActivity).format(
          "ddd DD MMM YYYY"
      );
    },
    sort(sortOrder) {
      let l = this.cardsData;
      if (sortOrder === 6) l.sort((a, b) => (a.l_Year < b.l_Year ? 1 : -1));
      if (sortOrder === 5) l.sort((a, b) => (a.l_Year > b.l_Year ? 1 : -1));
      if (sortOrder === 4)
        l.sort((a, b) => (Number(a.l_Odometer) < Number(b.l_Odometer) ? 1 : -1));
      if (sortOrder === 3)
        l.sort((a, b) => (Number(a.l_Odometer) > Number(b.l_Odometer) ? 1 : -1));
      if (sortOrder === 2)
        l.sort((a, b) =>
          JSON.parse(a.Prices)[0].Price < JSON.parse(b.Prices)[0].Price ? 1 : -1
        );
      if (sortOrder === 1)
        l.sort((a, b) =>
          JSON.parse(a.Prices)[0].Price > JSON.parse(b.Prices)[0].Price ? 1 : -1
        );
    },
    filterList() {
      const parameter = {
        ReservationStatus: ["6"],
        SelectedDateRange: -1
      };

      this.$store.dispatch("getReservationList", parameter)
          .then(() => {
            this.error = false;
          })
          .catch(() => {
            this.error = true;
          })
          .finally(() => {
            this.loading = false;
          })
    }
  },
  updated() {
    if (this.cardsData) {
      this.totalVehicles = this.cardsData.length;
    }
  },
  beforeMount() {
    this.filterList();
    this.$store.dispatch('setDashboardType', 'BuyerDashboard')
  },
};
</script>

<style lang="scss">
.vehicle-count {
  padding: 24px 0;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: #111111;
}
.card-wrapper {
  display: flex;
  flex-direction: column;
  column-gap: 16px;
  row-gap: 16px;
  flex-wrap: wrap;
  @media screen and (min-width:630px) {
  flex-direction: row;
  }
  .item {
    width: 100%;
    @media screen and (min-width:630px) {
      width: calc((100% / 2) - 11px);
    }
    @media screen and (min-width:1024px) {
      width: calc((100% / 3) - 11px);
    }
  }
}
.watchlist-card-footer {
  background: #e6f0f9;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding: 16px;
  row-gap: 10px;
  border-bottom: 4px solid $light-blue;
  min-height: 120px;
  button {
    background: none;
    color: $light-blue;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid $light-blue;
    border-radius: 40px;
    font-family: "Inter", sans-serif !important;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: $light-blue !important;
      color: white !important;
    }
    svg {
      margin-right: 8px;
    }
  }
  .title {
    font-size: 18px !important;
    color: #111111;
    font-weight: 500;
    margin-bottom: -10px;
    line-height: inherit;
    font-family: "Inter", sans-serif !important;
  }
  .date {
    font-size: 14px;
    color: #404040;
    margin-bottom: 8px;
  }
  .details-btn {
    width: 148px;
    height: 32px;
    border: 1px solid $light-blue;
    color: $light-blue;
    font-size: 14px;
    border-radius: 40px;
    margin-left: auto;
    padding: 5px 20px;
  }
}
</style>
